// exports.BASE_URL = "https://enlytical.azurewebsites.net/"; //prod
exports.BASE_URL = "https://older-enlytical-backend-dot-famous-archway-436113-a8.el.r.appspot.com/"; //prod
//exports.BASE_URL = "https://enlytical3.azurewebsites.net/"; //uat//
// exports.BASE_URL = "http://localhost:5000/";
exports.USER_TYPE_TO_DISPLAY = {
    enlytical_admin: 'Enlytical-Admin',
    agency_admin: 'Agency-admin',
    brand_admin: 'Brand-Admin',
    brand_viewer: 'Brand-Viewer',
};
exports.AD_TYPE_FULL_NAME = {
    SB: 'Sponsered Brands (SB)',
    SP: 'Sponsered Products (SP)',
    SD: 'Sponsered Display (SD)',
    SBVC: "SBVC"
};
exports.TARGET_TYPE_FULL_NAME = {
    kt: "Keyword Targeting (kt)",
    pt: "Product Targeting (pt)",
};


exports.AG_AR = {
    rH: 32,
    hH: 32
}

exports.asin_row_height = 36

exports.PERCENTAGE_CHANGE = {
    percentage_change: "Percentage change",
}

exports.product_master_headers = {
    ad_asin: "ASINs",
    pname: "Product Name"
}


// taali: cpid = '3145569791150530'
// so good :cpid = '4422554122982285'